import React, { useEffect, useState } from 'react';

const IntervalChrono = ({ seconds = 60, onFiveSecondsLeft }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now()); // Guarda el tiempo inicial
    setTimeLeft(seconds); // Restablece el tiempo restante

    const tick = () => {
      const elapsed = Math.floor((Date.now() - startTime) / 1000);
      const newTimeLeft = Math.max(seconds - elapsed, 0);
      setTimeLeft(newTimeLeft);

      if (newTimeLeft > 0) {
        requestAnimationFrame(tick); // Usamos requestAnimationFrame para precisión
      }
    };

    const timer = requestAnimationFrame(tick);

    return () => cancelAnimationFrame(timer);
  }, [seconds]);

  useEffect(() => {
    if (timeLeft === 5 && onFiveSecondsLeft) {
      onFiveSecondsLeft();
    }
  }, [timeLeft, onFiveSecondsLeft]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <h1>{formatTime(timeLeft)}</h1>
    </div>
  );
};

export default IntervalChrono;
