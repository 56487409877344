/* eslint-disable indent */
/* eslint-disable multiline-ternary */
/* eslint-disable curly */
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
// import { Context as Auth } from '../../../services/auth/AuthContext';
import { useParams } from 'react-router-dom';
import { getCenterByQuery } from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import {
  createSetting,
  getSettingById,
  patchSetting
} from '../../../services/setting.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { Option } from 'antd/es/mentions';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
// import { getIdToken } from 'firebase/auth';

const EditExerciseSettings = (props) => {
  // const { user } = useContext(Auth);
  const params = useParams();
  const [exercises, setExercises] = useState([]);
  const [setting, setSetting] = useState(null);
  const [center, setCenter] = useState(null);
  // const { firebaseUser } = useContext(Auth);
  const [exerciseType, setExerciseType] = useState('hist');
  const [setsNumber, setSetsNumber] = useState(1);
  const [sets, setSets] = useState([]);
  const [lastSet, setLastSet] = useState({
    percent: '100'
  });
  const [loading, setLoading] = useState(true);
  const [settingsForm] = Form.useForm();
  const { t } = useTranslation();

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        break;
      case 2:
        if (document.fullscreenEnabled) {
          document.documentElement.requestFullscreen();
        }
        break;
      case 3:
        break;
      default:
    }
  };

  useEffect(() => {
    // if (user.account) {
    loadCenter();
    if (!setting) {
      fillDefault();
    }
    // }
  }, []);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery({ _id: params.center });
    if (status === 200) {
      if (props.editSettings) {
        await loadSetting(props.editSettings._id);
      }

      await loadExercises(data.data[0]._id);
      setCenter(data.data[0]);
      setLoading(false);
    }
  };

  const loadSetting = async (id, token) => {
    const { status, data } = await getSettingById(id, token);
    if (status === 200) {
      setSetting(data.data);
      fillForm(data.data);
    }
  };

  const handleAddSet = (type = 'isometric') => {
    if (setsNumber < 15) {
      setSetsNumber(setsNumber + 1);
      if (type === 'sets') {
        setSets([...sets, { key: setsNumber + 1, time: '4', reps: '10' }]);
      } else {
        setSets([...sets, { key: setsNumber, time: '10', percent: '60' }]);
      }
    }
  };

  const handleRemoveSet = () => {
    if (setsNumber > 1) {
      setSetsNumber(setsNumber - 1);
      sets.pop();
      setSets([...sets]);
    }
  };

  const handleResetSets = () => {
    setSetsNumber(1);
    setSets([]);
  };

  const handleAddSetTime = (key) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        set.time = (parseInt(set.time) + 1).toString();
      }
      return set;
    });
    setSets(newSets);
  };

  const handleRemoveSetTime = (key) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        if (set.time > 1) {
          set.time = (parseInt(set.time) - 1).toString();
        }
      }
      return set;
    });
    setSets(newSets);
  };

  const handleChangeSetPercent = (key, value) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        set.percent = value;
      }
      return set;
    });
    setSets(newSets);
  };

  const handleAddSetReps = (key, value) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        set.reps = (parseInt(set.reps) + 1).toString();
      }
      return set;
    });
    setSets(newSets);
  };

  const handleRemoveSetReps = (key, value) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        if (set.reps > 1) {
          set.reps = (parseInt(set.reps) - 1).toString();
        }
      }
      return set;
    });
    setSets(newSets);
  };

  const handleChangeLastSetPercent = (value) => {
    setLastSet({ percent: value });
  };

  const renderIsometricSet = (set) => {
    return (
      <div>
        <div>
          <h4
            className={`text-color${center.theme}`}
            style={{ marginBottom: 0, width: '100%', textAlign: 'left' }}
          >
            {capitalize(t('set'))} #{set.key}
          </h4>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div>
            <div
              className={`text-color${center.theme}`}
              style={{ textAlign: 'left' }}
            >
              {capitalize(t('time'))}:
            </div>
            <Button onClick={() => handleRemoveSetTime(set.key)}>-</Button>
            <span
              className={`text-color${center.theme}`}
              style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}
            >
              {set.time}
              {'"'}
            </span>
            <Button onClick={() => handleAddSetTime(set.key)}>+</Button>
          </div>
          <div>
            <div
              className={`text-color${center.theme}`}
              style={{ textAlign: 'left' }}
            >
              {capitalize(t('percent'))}:
            </div>
            <Form.Item>
              <Select
                defaultValue={set.percent}
                onChange={(e) => handleChangeSetPercent(set.key, e)}
              >
                <Option value={'10'}>10%</Option>
                <Option value={'20'}>20%</Option>
                <Option value={'30'}>30%</Option>
                <Option value={'40'}>40%</Option>
                <Option value={'50'}>50%</Option>
                <Option value={'60'}>60%</Option>
                <Option value={'70'}>70%</Option>
                <Option value={'80'}>80%</Option>
                <Option value={'90'}>90%</Option>
                <Option value={'100'}>100%</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
    );
  };

  const renderSetsSet = (set) => {
    return (
      <div style={{ marginBottom: 20 }}>
        <div>
          <h4 style={{ marginBottom: 0, width: '100%', textAlign: 'left' }}>
            {capitalize(t('set'))} #{set.key}
          </h4>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div>
            <div style={{ textAlign: 'left' }}>{capitalize(t('reps'))}:</div>
            <Button onClick={() => handleRemoveSetReps(set.key)}>-</Button>
            <span style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}>
              {set.reps}
            </span>
            <Button onClick={() => handleAddSetReps(set.key)}>+</Button>
          </div>
          <div>
            <div style={{ textAlign: 'left' }}>
              {capitalize(t('repsTime'))}:
            </div>
            <Button onClick={() => handleRemoveSetTime(set.key)}>-</Button>
            <span style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}>
              {set.time}
              {'"'}
            </span>
            <Button onClick={() => handleAddSetTime(set.key)}>+</Button>
          </div>
        </div>
      </div>
    );
  };

  const fillForm = (setting) => {
    setExerciseType(setting.type);
    if (setting.type === 'hist') {
      settingsForm.setFieldsValue({
        exercise: setting.exercise._id,
        weight: setting.weight,
        fiber: setting.fiber,
        concentric: setting.concentric,
        eccentric: setting.eccentric,
        squeeze: setting.squeeze,
        progress: setting.progress,
        settings: setting.setting
      });
    } else if (setting.type === 'isometric') {
      setSetsNumber(setting.sets.length);
      setSets(setting.sets.filter((set) => set.time !== '-1'));
      settingsForm.setFieldsValue({
        exercise: setting.exercise._id,
        settings: setting.setting
      });
    } else if (setting.type === 'sets') {
      setSetsNumber(setting.sets.length);
      setSets(setting.sets);
      settingsForm.setFieldsValue({
        exercise: setting.exercise._id,
        weight: setting.weight,
        interval: setting.interval,
        settings: setting.setting
      });
    } else {
      settingsForm.setFieldsValue({
        exercise: setting.exercise._id,
        weight: setting.weight,
        fiber: setting.fiber,
        concentric: setting.concentric,
        eccentric: setting.eccentric,
        squeeze: setting.squeeze,
        progress: setting.progress,
        settings: setting.setting
      });
    }
  };

  // const deactivateSetting = async () => {
  //   if (setting) {
  //     let account;
  //     if (params.id) account = setting.account;
  //     else account = params.account;
  //     const { status } = await patchSetting(setting._id, { isActive: false });
  //     if (status === 200) {
  //       navigate('/dashboard/users/settings/' + account);
  //       message.success('Setting deleted successfully');
  //     }
  //   }
  // };

  const loadExercises = async (center, token) => {
    const { status, data } = await getExerciseByQuery(
      { center: center, isActive: true },
      token
    );
    if (status === 200) {
      setExercises(data.data);
    }
  };

  const fillDefault = () => {
    setExerciseType(props.exercise.type);
    if (props.exercise.type === 'hist') {
      settingsForm.setFieldsValue({
        exercise: props.exercise._id,
        concentric: props.exercise.concentric,
        eccentric: props.exercise.eccentric,
        squeeze: props.exercise.squeeze,
        settings: props.exercise.settings,
        fiber: 'MEDIUM',
        progress: 3
      });
    } else if (props.exercise.type === 'isometric') {
      const newSets = [];
      setSetsNumber(props.exercise.sets);
      for (let i = 1; i < props.exercise.sets; i++) {
        newSets.push({ key: i, time: '10', percent: '60' });
      }
      setSets(newSets);
      settingsForm.setFieldsValue({
        exercise: props.exercise._id,
        settings: props.exercise.settings
      });
    } else if (props.exercise.type === 'sets') {
      // handleResetSets();
      const newSets = [];
      setSetsNumber(props.exercise.sets);
      for (let i = 0; i < props.exercise.sets; i++) {
        newSets.push({ key: i + 1, time: '4', reps: '10' });
      }
      setSets(newSets);
      settingsForm.setFieldsValue({
        interval: props.exercise.interval,
        settings: props.exercise.settings
      });
    } else {
      settingsForm.setFieldsValue({
        exercise: props.exercise._id,
        concentric: props.exercise.concentric,
        eccentric: props.exercise.eccentric,
        squeeze: props.exercise.squeeze,
        settings: props.exercise.settings,
        fiber: 'MEDIUM',
        progress: 3
      });
    }
  };

  const createNewSetting = async (data, token) => {
    const account = props.account._id;
    let formData;
    if (exerciseType === 'hist') {
      formData = {
        exercise: props.exercise._id,
        weight: data.weight,
        fiber: data.fiber,
        eccentric: data.eccentric,
        concentric: data.concentric,
        squeeze: data.squeeze,
        progress: data.progress,
        type: exerciseType,
        setting: data.settings,
        account: account
      };
    } else if (exerciseType === 'isometric') {
      const newSets = [...sets];
      newSets.push({
        key: setsNumber,
        time: '-1',
        percent: lastSet.percent
      });
      formData = {
        exercise: props.exercise._id,
        setting: data.settings,
        sets: newSets,
        type: exerciseType,
        account: account
      };
    } else if (exerciseType === 'sets') {
      const newSets = [...sets];
      formData = {
        exercise: props.exercise._id,
        weight: data.weight,
        interval: data.interval,
        setting: data.settings,
        sets: newSets,
        type: exerciseType,
        account: account
      };
    } else {
      formData = {
        exercise: props.exercise._id,
        weight: data.weight,
        fiber: data.fiber,
        eccentric: data.eccentric,
        concentric: data.concentric,
        squeeze: data.squeeze,
        progress: data.progress,
        type: exerciseType,
        setting: data.settings,
        account: account
      };
    }

    if (props.editSettings)
      await patchSetting(props.editSettings._id, formData, token);
    else await createSetting(formData, token);

    // setTimeout(() => {
    props.setNoSettings(false);
    props.setShowSettingsConfig(false);
    props.setSettingsForm(false);
    // props.reloadSettings();
    // }, 3000);
  };

  const correctClave = async (e) => {
    await createNewSetting(e);
  };

  if (loading) {
    return <Spinner dark />;
  }
  return (
    <div className={`background-main${center.theme}`}>
      <div
        className={`background-sub${center.theme}`}
        style={{
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          wrap: 'nowrap'
        }}
        onClick={handleClick}
      >
        <Form
          form={settingsForm}
          onFinish={correctClave}
          style={{
            width: '100%',
            maxWidth: 400
          }}
        >
          <Form.Item className={`text-color${center.theme}`}>
            {setting ? (
              <h1 className={`text-color${center.theme}`}>
                {capitalizeAll(t('editSetting'))}
              </h1>
            ) : (
              <h1 className={`text-color${center.theme}`}>
                {capitalizeAll(t('createSetting'))}
              </h1>
            )}
          </Form.Item>
          <div
            className={`text-color${center.theme}`}
            style={{ textAlign: 'left' }}
          >
            {capitalizeAll(t('exercise'))}:
          </div>
          <Form.Item
            name='exercise'
            rules={[
              {
                required: false,
                message: capitalize(t('exercise')) + ' ' + t('isRequired')
              }
            ]}
          >
            <Select
              defaultValue={
                setting
                  ? setting.exercise
                  : exercises.find((e) => e._id === props.exercise._id)._id
              }
              disabled
            >
              <Option value='null'>
                {capitalizeAll(t('selectExercise'))}e
              </Option>
              {exercises.map((exercise, key) => {
                return (
                  <Option key={key} value={exercise._id}>
                    {exercise.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {exerciseType === 'hist' && (
            <>
              <Form.Item
                name='weight'
                rules={[
                  {
                    required: true,
                    message:
                      capitalize(t('recommendedWeight')) + ' ' + t('isRequired')
                  }
                ]}
              >
                <Input
                  addonBefore={capitalize(t('recommendedWeight'))}
                  name='weight'
                  type={'number'}
                  onWheel={(e) => e.target.blur()}
                  defaultValue={setting ? setting.weight : ''}
                  placeholder={capitalize(t('recommendedWeight'))}
                />
              </Form.Item>
              <div
                className={`text-color${center.theme}`}
                style={{ textAlign: 'left' }}
              >
                {capitalize(t('fiber'))}:
              </div>
              <Form.Item
                name='fiber'
                rules={[
                  {
                    required: true,
                    message: 'Fiber is required'
                  }
                ]}
              >
                <Select defaultValue={setting ? setting.fiber : 'MEDIUM'}>
                  <Option value={'SHORT'}>{t('tulShort')}</Option>
                  <Option value={'MEDIUM'}>{t('tulMedium')}</Option>
                  <Option value={'LONG'}>{t('tulLong')}</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name='concentric'
                rules={[
                  {
                    required: true,
                    message: capitalize(t('concentric')) + ' ' + t('isRequired')
                  }
                ]}
              >
                <Input
                  addonBefore={capitalize(t('concentric'))}
                  name='concentric'
                  type={'number'}
                  onWheel={(e) => e.target.blur()}
                  defaultValue={setting ? setting.concentric : ''}
                  placeholder={capitalize(t('concentric'))}
                />
              </Form.Item>
              <Form.Item
                name='eccentric'
                rules={[
                  {
                    required: true,
                    message: capitalize(t('eccentric')) + ' ' + t('isRequired')
                  }
                ]}
              >
                <Input
                  addonBefore={capitalize(t('eccentric'))}
                  name='eccentric'
                  type={'number'}
                  onWheel={(e) => e.target.blur()}
                  defaultValue={setting ? setting.eccentric : ''}
                  placeholder={capitalize(t('eccentric'))}
                />
              </Form.Item>
              <Form.Item
                name='squeeze'
                rules={[
                  {
                    required: true,
                    message: capitalize(t('squeeze')) + ' ' + t('isRequired')
                  }
                ]}
              >
                <Input
                  addonBefore={capitalize(t('squeeze'))}
                  name='squeeze'
                  type={'number'}
                  onWheel={(e) => e.target.blur()}
                  defaultValue={setting ? setting.squeeze : ''}
                  placeholder={capitalize(t('squeeze'))}
                />
              </Form.Item>
              <div
                className={`text-color${center.theme}`}
                style={{ textAlign: 'left' }}
              >
                {capitalize(t('progress'))}:
              </div>
              <Form.Item
                name='progress'
                rules={[
                  {
                    required: true,
                    message: 'Progress is required'
                  }
                ]}
              >
                <Select defaultValue={setting ? setting.progress : 3}>
                  <Option value={1}>1%</Option>
                  <Option value={2}>2%</Option>
                  <Option value={3}>3%</Option>
                  <Option value={4}>4%</Option>
                  <Option value={5}>5%</Option>
                </Select>
              </Form.Item>
            </>
          )}

          {exerciseType === 'isometric' && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <Button onClick={() => handleRemoveSet()}>-</Button>
                <span
                  className={`text-color${center.theme}`}
                  style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}
                >
                  {setsNumber} {capitalize(t('sets'))}
                </span>
                <Button onClick={() => handleAddSet()}>+</Button>
              </div>
              {sets.map((set, key) => {
                return renderIsometricSet(set, key);
              })}
              <div>
                <div>
                  <h4
                    className={`text-color${center.theme}`}
                    style={{
                      marginBottom: 0,
                      width: '100%',
                      textAlign: 'left'
                    }}
                  >
                    {capitalize(t('set'))} #{sets.length + 1}
                  </h4>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <div>
                    <div
                      className={`text-color${center.theme}`}
                      style={{ textAlign: 'left' }}
                    >
                      {capitalize(t('time'))}:
                    </div>
                    <span
                      className={`text-color${center.theme}`}
                      style={{ fontSize: '1rem' }}
                    >
                      {capitalize(t('noLimit'))}
                    </span>
                  </div>
                  <div>
                    <div
                      className={`text-color${center.theme}`}
                      style={{ textAlign: 'left' }}
                    >
                      {capitalize(t('percent'))}:
                    </div>
                    <Form.Item>
                      <Select
                        defaultValue={lastSet.percent}
                        onChange={(e) => handleChangeLastSetPercent(e)}
                      >
                        <Option value={'10'}>10%</Option>
                        <Option value={'20'}>20%</Option>
                        <Option value={'30'}>30%</Option>
                        <Option value={'40'}>40%</Option>
                        <Option value={'50'}>50%</Option>
                        <Option value={'60'}>60%</Option>
                        <Option value={'70'}>70%</Option>
                        <Option value={'80'}>80%</Option>
                        <Option value={'90'}>90%</Option>
                        <Option value={'100'}>100%</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </>
          )}

          {exerciseType === 'sets' && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center'
                }}
              >
                <Button onClick={() => handleRemoveSet()}>-</Button>
                <span
                  style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}
                >
                  {setsNumber} {capitalize(t('sets'))}
                </span>
                <Button onClick={() => handleAddSet('sets')}>+</Button>
              </div>
              {sets.map((set, key) => {
                return renderSetsSet(set, key);
              })}

              <div style={{ marginTop: 30 }}>
                <Form.Item
                  name='interval'
                  rules={[
                    {
                      required: true,
                      message: capitalize(t('interval')) + ' ' + t('isRequired')
                    }
                  ]}
                >
                  <Input
                    addonBefore={capitalize(t('interval'))}
                    name='interval'
                    onWheel={(e) => e.target.blur()}
                    type={'number'}
                    defaultValue={setting ? setting.interval : ''}
                    placeholder={capitalize(t('interval'))}
                  />
                </Form.Item>
                <Form.Item
                  name='weight'
                  rules={[
                    {
                      required: true,
                      message: capitalize(t('weight')) + ' ' + t('isRequired')
                    }
                  ]}
                >
                  <Input
                    addonBefore={capitalize(t('weight'))}
                    name='weight'
                    onWheel={(e) => e.target.blur()}
                    type={'number'}
                    defaultValue={setting ? setting.weight : ''}
                    placeholder={capitalize(t('weight'))}
                  />
                </Form.Item>
              </div>
            </>
          )}

          <div
            className={`text-color${center.theme}`}
            style={{ textAlign: 'left' }}
          >
            {capitalize(t('settings'))}:
          </div>
          <Form.Item
            name='settings'
            rules={[
              {
                required: false,
                message: capitalize(t('settings')) + ' ' + t('isRequired')
              }
            ]}
          >
            <TextArea
              addonBefore={capitalize(t('settings'))}
              rows={4}
              name='settings'
              defaultValue={setting ? setting.setting : ''}
              placeholder={capitalize(t('settings'))}
            />
          </Form.Item>
          <div
            style={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {/* {setting && ( */}
            {/*  <Popconfirm */}
            {/*    title="Are you sure to delete this setting?" */}
            {/*    onConfirm={deactivateSetting} */}
            {/*    // onCancel={cancel} */}
            {/*    okText="Yes" */}
            {/*    cancelText="No" */}
            {/*  > */}
            {/*    <Form.Item> */}
            {/*      <Button */}
            {/*        type="primary" */}
            {/*        danger */}
            {/*        className="delete-form-button" */}
            {/*        style={{ marginRight: 10 }} */}
            {/*        // onClick={deactivateUser} */}
            {/*      > */}
            {/*        <DeleteOutlined /> */}
            {/*      </Button> */}
            {/*    </Form.Item> */}
            {/*  </Popconfirm> */}
            {/* )} */}
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
                style={{ marginRight: 10 }}
              >
                {setting
                  ? capitalizeAll(t('saveChanges'))
                  : capitalizeAll(t('createSetting'))}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default EditExerciseSettings;
