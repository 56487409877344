/* eslint-disable multiline-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { Input, Button, Select, message, Popconfirm } from 'antd';
import Form from 'antd/es/form/Form';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCenterByQuery,
  getCenterById
} from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import {
  createSetting,
  getSettingById,
  patchSetting,
  getSettingByQuery
} from '../../../services/setting.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { Option } from 'antd/es/mentions';
import TextArea from 'antd/es/input/TextArea';
import { getIdToken } from 'firebase/auth';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateSetting = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [exercises, setExercises] = useState([]);
  const [setting, setSetting] = useState(null);
  const { firebaseUser } = useContext(Auth);
  const [exerciseType, setExerciseType] = useState('hist');
  const [setsNumber, setSetsNumber] = useState(1);
  const [sets, setSets] = useState([]);
  const [lastSet, setLastSet] = useState({
    percent: '100'
  });
  const [loading, setLoading] = useState(true);
  const [settingsForm] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery(
      { organizer: user.account._id },
      user.token
    );
    if (status === 200) {
      if (params.id) {
        const setting = await loadSetting(params.id, user.token);
        await loadExercises(data.data[0]._id, user.token, setting);
      }
      await loadExercises(data.data[0]._id, user.token);
      setLoading(false);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(
      user.account.center,
      user.token
    );
    if (status === 200) {
      if (params.id) {
        const setting = await loadSetting(params.id, user.token);
        await loadExercises(data.data[0]._id, user.token, setting);
      }
      await loadExercises(data.data._id, user.token);
      setLoading(false);
    }
  };

  const loadSetting = async (id, token) => {
    const { status, data } = await getSettingById(id, token);
    if (status === 200) {
      setSetting(data.data);
      fillForm(data.data);
      setLoading(false);
      return data.data;
    }
  };

  const handleAddSet = (type = 'isometric') => {
    if (setsNumber < 15) {
      setSetsNumber(setsNumber + 1);
      if (type === 'sets') {
        setSets([...sets, { key: setsNumber + 1, time: '4', reps: '10' }]);
      } else {
        setSets([...sets, { key: setsNumber, time: '10', percent: '60' }]);
      }
    }
  };

  const handleRemoveSet = () => {
    if (setsNumber > 1) {
      setSetsNumber(setsNumber - 1);
      sets.pop();
      setSets([...sets]);
    }
  };

  const handleResetSets = () => {
    setSetsNumber(1);
    setSets([]);
  };

  const handleAddSetTime = (key) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        set.time = (parseInt(set.time) + 1).toString();
      }
      return set;
    });
    setSets(newSets);
  };

  const handleRemoveSetTime = (key) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        if (set.time > 1) {
          set.time = (parseInt(set.time) - 1).toString();
        }
      }
      return set;
    });
    setSets(newSets);
  };

  const handleChangeSetPercent = (key, value) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        set.percent = value;
      }
      return set;
    });
    setSets(newSets);
  };

  const handleAddSetReps = (key, value) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        set.reps = (parseInt(set.reps) + 1).toString();
      }
      return set;
    });
    setSets(newSets);
  };

  const handleRemoveSetReps = (key, value) => {
    const newSets = sets.map((set) => {
      if (set.key === key) {
        if (set.reps > 1) {
          set.reps = (parseInt(set.reps) - 1).toString();
        }
      }
      return set;
    });
    setSets(newSets);
  };

  const handleChangeLastSetPercent = (value) => {
    setLastSet({ percent: value });
  };

  const renderIsometricSet = (set) => {
    return (
      <div>
        <div>
          <h4 style={{ marginBottom: 0, width: '100%', textAlign: 'left' }}>
            {capitalize(t('set'))} #{set.key}
          </h4>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div>
            <div style={{ textAlign: 'left' }}>{capitalize(t('time'))}:</div>
            <Button onClick={() => handleRemoveSetTime(set.key)}>-</Button>
            <span style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}>
              {set.time}
              {'"'}
            </span>
            <Button onClick={() => handleAddSetTime(set.key)}>+</Button>
          </div>
          <div>
            <div style={{ textAlign: 'left' }}>{capitalize(t('percent'))}:</div>
            <Form.Item>
              <Select
                defaultValue={set.percent}
                onChange={(e) => handleChangeSetPercent(set.key, e)}
              >
                <Option value={'10'}>10%</Option>
                <Option value={'20'}>20%</Option>
                <Option value={'30'}>30%</Option>
                <Option value={'40'}>40%</Option>
                <Option value={'50'}>50%</Option>
                <Option value={'60'}>60%</Option>
                <Option value={'70'}>70%</Option>
                <Option value={'80'}>80%</Option>
                <Option value={'90'}>90%</Option>
                <Option value={'100'}>100%</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
    );
  };

  const renderSetsSet = (set) => {
    return (
      <div style={{ marginBottom: 20 }}>
        <div>
          <h4 style={{ marginBottom: 0, width: '100%', textAlign: 'left' }}>
            {capitalize(t('set'))} #{set.key}
          </h4>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div>
            <div style={{ textAlign: 'left' }}>{capitalize(t('reps'))}:</div>
            <Button onClick={() => handleRemoveSetReps(set.key)}>-</Button>
            <span style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}>
              {set.reps}
            </span>
            <Button onClick={() => handleAddSetReps(set.key)}>+</Button>
          </div>
          <div>
            <div style={{ textAlign: 'left' }}>
              {capitalize(t('repsTime'))}:
            </div>
            <Button onClick={() => handleRemoveSetTime(set.key)}>-</Button>
            <span style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}>
              {set.time}
              {'"'}
            </span>
            <Button onClick={() => handleAddSetTime(set.key)}>+</Button>
          </div>
        </div>
      </div>
    );
  };

  const fillForm = (setting) => {
    setExerciseType(setting.type);
    if (setting.type === 'hist') {
      settingsForm.setFieldsValue({
        exercise: setting.exercise,
        weight: setting.weight,
        fiber: setting.fiber,
        concentric: setting.concentric,
        eccentric: setting.eccentric,
        squeeze: setting.squeeze,
        progress: setting.progress,
        settings: setting.setting
      });
    } else if (setting.type === 'isometric') {
      setSetsNumber(setting.sets.length);
      setSets(setting.sets.filter((set) => set.time !== '-1'));
      settingsForm.setFieldsValue({
        exercise: setting.exercise,
        settings: setting.setting
      });
    } else if (setting.type === 'sets') {
      setSetsNumber(setting.sets.length);
      setSets(setting.sets);
      settingsForm.setFieldsValue({
        exercise: setting.exercise,
        weight: setting.weight,
        interval: setting.interval,
        settings: setting.setting
      });
    } else if (setting.type === 'strength') {
      settingsForm.setFieldsValue({
        exercise: setting.exercise,
        settings: setting.setting
      });
    } else {
      settingsForm.setFieldsValue({
        exercise: setting.exercise,
        weight: setting.weight,
        fiber: setting.fiber,
        concentric: setting.concentric,
        eccentric: setting.eccentric,
        squeeze: setting.squeeze,
        progress: setting.progress,
        settings: setting.setting
      });
    }
  };

  const deactivateSetting = async () => {
    if (setting) {
      let account;
      if (params.id) account = setting.account;
      else account = params.account;
      const { status } = await patchSetting(
        setting._id,
        { isActive: false },
        user.token
      );
      if (status === 200) {
        navigate('/dashboard/users/settings/' + account);
        message.success(capitalize(t('settingDeleted')));
      }
    }
  };

  const loadExercises = async (center, token, settingData) => {
    let account;
    if (params.id && settingData) account = settingData.account;
    else account = params.account;
    const { status, data } = await getExerciseByQuery(
      { center: center, isActive: true },
      token
    );
    if (status === 200) {
      const availableExercices = [];
      for await (const exercise of data.data) {
        if (!settingData) {
          const settings = await getSettingByQuery(
            { exercise: exercise._id, account: account, isActive: true },
            token
          );
          if (settings.data.data.length === 0) {
            availableExercices.push(exercise);
          }
        } else {
          availableExercices.push(exercise);
        }
      }
      setExercises(
        availableExercices.sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  };

  const handleDefaultValueExercise = (value) => {
    if (value) {
      const exercise = exercises.find((exercise) => exercise._id === value);
      if (exercise) {
        setExerciseType(exercise.type);
        if (exercise.type === 'hist') {
          settingsForm.setFieldsValue({
            concentric: exercise.concentric,
            eccentric: exercise.eccentric,
            squeeze: exercise.squeeze,
            settings: exercise.settings,
            fiber: 'MEDIUM',
            progress: 3
          });
        } else if (exercise.type === 'isometric') {
          // handleResetSets();
          const newSets = [];
          setSetsNumber(exercise.sets);
          for (let i = 1; i < exercise.sets; i++) {
            newSets.push({ key: i, time: '10', percent: '60' });
          }
          setSets(newSets);
          settingsForm.setFieldsValue({
            settings: exercise.settings
          });
        } else if (exercise.type === 'sets') {
          // handleResetSets();
          const newSets = [];
          setSetsNumber(exercise.sets);
          for (let i = 0; i < exercise.sets; i++) {
            newSets.push({ key: i + 1, time: '4', reps: '10' });
          }
          setSets(newSets);
          settingsForm.setFieldsValue({
            interval: exercise.interval,
            settings: exercise.settings
          });
        } else if (exercise.type === 'strength') {
          settingsForm.setFieldsValue({
            settings: exercise.settings
          });
        } else {
          settingsForm.setFieldsValue({
            concentric: exercise.concentric,
            eccentric: exercise.eccentric,
            squeeze: exercise.squeeze,
            settings: exercise.settings,
            fiber: 'MEDIUM',
            progress: 3
          });
        }
      }
    }
  };

  const createNewSetting = async (data, token) => {
    let account;
    let formData;
    if (params.id) account = setting.account;
    else account = params.account;

    if (exerciseType === 'hist') {
      formData = {
        exercise: data.exercise,
        weight: data.weight,
        fiber: data.fiber,
        eccentric: data.eccentric,
        concentric: data.concentric,
        squeeze: data.squeeze,
        progress: data.progress,
        setting: data.settings,
        type: exerciseType,
        account: account
      };
    } else if (exerciseType === 'isometric') {
      const newSets = [...sets];
      newSets.push({
        key: setsNumber,
        time: '-1',
        percent: lastSet.percent
      });
      formData = {
        exercise: data.exercise,
        setting: data.settings,
        sets: newSets,
        type: exerciseType,
        account: account
      };
    } else if (exerciseType === 'sets') {
      const newSets = [...sets];
      formData = {
        exercise: data.exercise,
        weight: data.weight,
        interval: data.interval,
        setting: data.settings,
        sets: newSets,
        type: exerciseType,
        account: account
      };
    } else if (exerciseType === 'strength') {
      formData = {
        exercise: data.exercise,
        setting: data.settings,
        type: exerciseType,
        account: account
      };
    } else {
      formData = {
        exercise: data.exercise,
        weight: data.weight,
        fiber: data.fiber,
        eccentric: data.eccentric,
        concentric: data.concentric,
        squeeze: data.squeeze,
        progress: data.progress,
        setting: data.settings,
        type: exerciseType,
        account: account
      };
    }
    if (params.id) await patchSetting(params.id, formData, token);
    else await createSetting(formData, token);
    navigate('/dashboard/users/settings/' + account);
  };

  const correctClave = async (e) => {
    await createNewSetting(e, user.token);
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form
        form={settingsForm}
        onFinish={correctClave}
        style={{
          width: '100%',
          maxWidth: 400
        }}
      >
        <Form.Item>
          {setting ? (
            <h1>{capitalizeAll(t('editSetting'))}</h1>
          ) : (
            <h1>{capitalizeAll(t('createSetting'))}</h1>
          )}
        </Form.Item>
        <div style={{ textAlign: 'left' }}>{capitalizeAll(t('exercise'))}:</div>
        <Form.Item
          name='exercise'
          rules={[
            {
              required: true,
              message: capitalize(t('exercise')) + ' ' + t('isRequired')
            }
          ]}
        >
          <Select
            defaultValue={setting ? setting.exercise : 'null'}
            onChange={(e) => handleDefaultValueExercise(e)}
            disabled={!!setting}
          >
            <Option value='null'>{capitalizeAll(t('selectExercise'))}</Option>
            {exercises.map((exercise, key) => {
              return (
                <Option key={key} value={exercise._id}>
                  {exercise.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {exerciseType === 'hist' && (
          <>
            <Form.Item
              name='weight'
              rules={[
                {
                  required: true,
                  message:
                    capitalize(t('recommendedWeight')) + ' ' + t('isRequired')
                }
              ]}
            >
              <Input
                addonBefore={capitalize(t('recommendedWeight'))}
                name='weight'
                onWheel={(e) => e.target.blur()}
                type={'number'}
                defaultValue={setting ? setting.weight : ''}
                placeholder={capitalize(t('recommendedWeight'))}
              />
            </Form.Item>
            <div style={{ textAlign: 'left' }}>{capitalize(t('fiber'))}:</div>
            <Form.Item name='fiber'>
              <Select defaultValue={setting ? setting.fiber : 'MEDIUM'}>
                <Option value={'SHORT'}>{t('tulShort')} [1:20 - 1:40]</Option>
                <Option value={'MEDIUM'}>{t('tulMedium')}[1:40 - 2:10]</Option>
                <Option value={'LONG'}>{t('tulLong')} [2:00 - 2:40]</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='concentric'
              rules={[
                {
                  required: true,
                  message: capitalize(t('concentric')) + ' ' + t('isRequired')
                }
              ]}
            >
              <Input
                addonBefore={capitalize(t('concentric'))}
                name='concentric'
                defaultValue={setting ? setting.concentric : ''}
                placeholder={capitalize(t('concentric'))}
              />
            </Form.Item>
            <Form.Item
              name='eccentric'
              rules={[
                {
                  required: true,
                  message: capitalize(t('eccentric')) + ' ' + t('isRequired')
                }
              ]}
            >
              <Input
                addonBefore={capitalize(t('eccentric'))}
                name='eccentric'
                defaultValue={setting ? setting.eccentric : ''}
                placeholder={capitalize(t('eccentric'))}
              />
            </Form.Item>
            <Form.Item
              name='squeeze'
              rules={[
                {
                  required: true,
                  message: capitalize(t('squeeze')) + ' ' + t('isRequired')
                }
              ]}
            >
              <Input
                addonBefore={capitalize(t('squeeze'))}
                name='squeeze'
                defaultValue={setting ? setting.squeeze : ''}
                placeholder={capitalize(t('squeeze'))}
              />
            </Form.Item>
            <div style={{ textAlign: 'left' }}>
              {capitalize(t('progress'))}:
            </div>
            <Form.Item name='progress'>
              <Select defaultValue={setting ? setting.progress : 3}>
                <Option value={1}>1%</Option>
                <Option value={2}>2%</Option>
                <Option value={3}>3%</Option>
                <Option value={4}>4%</Option>
                <Option value={5}>5%</Option>
              </Select>
            </Form.Item>
          </>
        )}

        {exerciseType === 'isometric' && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Button onClick={() => handleRemoveSet()}>-</Button>
              <span
                style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}
              >
                {setsNumber} {capitalize(t('sets'))}
              </span>
              <Button onClick={() => handleAddSet()}>+</Button>
            </div>
            {sets.map((set, key) => {
              return renderIsometricSet(set, key);
            })}
            <div>
              <div>
                <h4
                  style={{ marginBottom: 0, width: '100%', textAlign: 'left' }}
                >
                  {capitalize(t('set'))} #{sets.length + 1}
                </h4>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <div>
                  <div style={{ textAlign: 'left' }}>
                    {capitalize(t('time'))}:
                  </div>
                  <span style={{ fontSize: '1rem' }}>
                    {capitalize(t('noLimit'))}
                  </span>
                </div>
                <div>
                  <div style={{ textAlign: 'left' }}>
                    {capitalize(t('percent'))}:
                  </div>
                  <Form.Item>
                    <Select
                      defaultValue={lastSet.percent}
                      onChange={(e) => handleChangeLastSetPercent(e)}
                    >
                      <Option value={'10'}>10%</Option>
                      <Option value={'20'}>20%</Option>
                      <Option value={'30'}>30%</Option>
                      <Option value={'40'}>40%</Option>
                      <Option value={'50'}>50%</Option>
                      <Option value={'60'}>60%</Option>
                      <Option value={'70'}>70%</Option>
                      <Option value={'80'}>80%</Option>
                      <Option value={'90'}>90%</Option>
                      <Option value={'100'}>100%</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </>
        )}

        {exerciseType === 'sets' && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Button onClick={() => handleRemoveSet()}>-</Button>
              <span
                style={{ fontSize: '1.2rem', marginRight: 5, marginLeft: 5 }}
              >
                {setsNumber} {capitalize(t('sets'))}
              </span>
              <Button onClick={() => handleAddSet('sets')}>+</Button>
            </div>
            {sets.map((set, key) => {
              return renderSetsSet(set, key);
            })}

            <div style={{ marginTop: 30 }}>
              <Form.Item
                name='interval'
                rules={[
                  {
                    required: true,
                    message: capitalize(t('interval')) + ' ' + t('isRequired')
                  }
                ]}
              >
                <Input
                  addonBefore={capitalize(t('interval'))}
                  name='interval'
                  onWheel={(e) => e.target.blur()}
                  type={'number'}
                  defaultValue={setting ? setting.interval : ''}
                  placeholder={capitalize(t('interval'))}
                />
              </Form.Item>
              <Form.Item
                name='weight'
                rules={[
                  {
                    required: true,
                    message: capitalize(t('weight')) + ' ' + t('isRequired')
                  }
                ]}
              >
                <Input
                  addonBefore={capitalize(t('weight'))}
                  name='weight'
                  onWheel={(e) => e.target.blur()}
                  type={'number'}
                  defaultValue={setting ? setting.weight : ''}
                  placeholder={capitalize(t('weight'))}
                />
              </Form.Item>
            </div>
          </>
        )}
        <div style={{ textAlign: 'left' }}>{capitalize(t('settings'))}:</div>
        <Form.Item
          name='settings'
          rules={[
            {
              required: true,
              message: capitalize(t('settings')) + ' ' + t('isRequired')
            }
          ]}
        >
          <TextArea
            addonBefore={capitalize(t('settings'))}
            rows={4}
            name='settings'
            defaultValue={setting ? setting.setting : ''}
            placeholder={capitalize(t('settings'))}
          />
        </Form.Item>
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {setting && (
            <Popconfirm
              title={capitalize(t('confirmDeleteSetting'))}
              onConfirm={deactivateSetting}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type='primary'
                  danger
                  className='delete-form-button'
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              style={{ marginRight: 10 }}
            >
              {setting
                ? capitalizeAll(t('saveChanges'))
                : capitalizeAll(t('createSetting'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateSetting;
