/* eslint-disable multiline-ternary */
import React, { useState } from 'react';
import { Button, Collapse, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';
import { patchNotification } from '../../services/notification.service';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const NotificationModal = (props) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState(props.notifications);
  const { Panel } = Collapse;

  const markAsReaded = (e) => {
    if (!e) return;
    const updateNotifications = [...notifications];
    const notification = updateNotifications.find((n) => n._id === e);
    if (!notification.isPersistent) {
      notification.isReaded = true;
      patchNotification(notification._id, { isReaded: true });
      setNotifications(updateNotifications);
      checkNewNotifications();
    }
  };

  const checkNewNotifications = () => {
    const newNotifications = notifications.filter((n) => !n.isReaded);
    props.setNewNotification(newNotifications.length > 0);
  };

  const deactivateNotification = async (notification) => {
    if (notification) {
      const { status } = await patchNotification(notification._id, {
        isActive: false
      });
      if (status === 200) {
        console.log('llega 1', notification);
        props.loadNotifications(props.accountId);
      }
    }
  };

  const notificationPanel = (notification) => {
    return (
      <Panel
        header={notification.title}
        key={notification._id}
        style={{
          fontWeight:
            notification.isReaded && !notification.isPersistent
              ? 'normal'
              : 'bold'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '90%' }}>
            <p>{notification.message}</p>
          </div>
          <div style={{ width: '5%', textAlign: 'right' }}>
            {!notification.isPersistent && (
              <Popconfirm
                title={capitalize(t('confirmDeleteNotification'))}
                onConfirm={() => deactivateNotification(notification)}
                // onCancel={cancel}
                okText={capitalize(t('yes'))}
                cancelText={capitalize(t('no'))}
              >
                <Button
                  type='primary'
                  danger
                  className='delete-form-button'
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
      </Panel>
    );
  };

  return (
    <>
      <Modal
        title={capitalizeAll(t('notifications'))}
        visible={props.notificationModalVisible}
        onOk={() => {
          props.setNotificationModalVisible(false);
        }}
        onCancel={() => {
          props.setNotificationModalVisible(false);
        }}
        onPan={(p) => {}}
        centered
        width={'100%'}
        footer={[
          <Button
            key='OK'
            onClick={() => {
              props.setNotificationModalVisible(false);
            }}
          >
            OK
          </Button>
        ]}
      >
        <div>
          {notifications.length === 0 ? (
            <p>{capitalize(t('noNotifications'))}</p>
          ) : (
            <Collapse accordion onChange={(e) => markAsReaded(e)}>
              {props.notifications.map((notification) =>
                notificationPanel(notification)
              )}
            </Collapse>
          )}
        </div>
      </Modal>
    </>
  );
};

export default NotificationModal;
